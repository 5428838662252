import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/AboutUsContent'
import Customers from '../components/AboutUs/Customers'
import Testimonials from '../components/AboutUs/Testimonials'
import Seo from '../components/App/Seo'
import logentisLogo from '../assets/images/logo.png'
import {graphql} from "gatsby";

const AboutUs = ({data, location: {origin}}) => {
  const siteUrl = data.site.siteMetadata.siteUrl;
  return (
    <Layout>
      <Seo title={"Über uns. Wer wir sind und was wir machen."}
           description={"Alle Informationen rund um LOGENTIS wie Firmenhistorie, Spendeninitiativen und " +
           "Projekte mit unseren Kunden."}
           image={"/images/og/logentis-main-og.png"}
      >

        <script type="application/ld+json">{`
                [{
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "de",
                    "addressLocality": "Osnabrück, Niedersachsen",
                    "postalCode": "49084",
                    "streetAddress": "Franz-Lenz Str. 4"
                  },
                  "email": "info@logentis.de",
                  "name": "LOGENTIS GmbH",
                  "url": "${siteUrl}",
                  "logo": "https://logentis.de${logentisLogo}",
                  "telephone": "+49 (0)541 580587 0"
                },
                {
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://logentis.de"
                  },{
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Über uns",
                    "item": "https://logentis.de/ueber-uns/"
                  }]
                }]
              `}
        </script>
      </Seo>
      <Navbar />
      <PageBanner
        pageTitle="Über LOGENTIS"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Über uns"
      />
      <AboutUsContent />
      <Customers/>
      <Testimonials />
      <Footer />
    </Layout>
  );
}


export default AboutUs;

export const pageQuery = graphql`
query {
    site {
      siteMetadata {
        siteUrl
      }
    }
}
`;
