import React from 'react'
import { graphql, Link, StaticQuery } from "gatsby"
import shape from '../../assets/images/shape/shape1.svg'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = ({data}) => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <section className="testimonials-area bg-f1f8fb">
            <div className="container pt-70 pb-70">
                <div className="section-title">
                    <h2>Was unsere Kunden sagen?</h2>
                    <p>Wer wenn nicht unsere Kunden können besser beurteilen wie wir arbeiten. </p>
                </div>

                {display ? <OwlCarousel
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                >
                    {data.allTestimonialsJson.nodes.map((testimonial) => (
                      <div key={testimonial.id} className="single-testimonials-item d-flex flex-column"
                           style={{minHeight: "450px"}}>
                          <div style={{flexGrow: 0}}>{testimonial.testimonial_hp}</div>

                          <div className="client-info d-flex justify-content-center"
                                style={{flexGrow: 1, alignItems: "flex-end"}}>
                              <div className="d-flex align-items-center">
                                  <GatsbyImage alt={testimonial.name}
                                               image={getImage(testimonial.featuredimage)}/>
                                  <div className="title">
                                      <h3>{testimonial.name}</h3>
                                      <span style={{color: "#5a5a5a"}}>
                                          {testimonial.position},&nbsp;
                                          {testimonial.projecturl && <Link to={testimonial.projecturl}>{testimonial.firma}</Link>}
                                          {!testimonial.projecturl && testimonial.firma}
                                      </span>
                                  </div>
                              </div>
                          </div>
                      </div>
                    ))}
                </OwlCarousel> : ''}

                {/*<div className="testimonials-view-btn text-center">
                    <Link to="/testimonials" className="default-btn">
                        <i className="flaticon-view"/>
                        Check Out All Reviews
                    </Link>
                </div>*/}
            </div>

            <div className="shape-img1">
                <img src={shape} alt="about" />
            </div>
        </section>
    )
}

const Main =  () => (
  <StaticQuery
    query={graphql`
      query TestimonialsQuery {
        allTestimonialsJson {
            nodes {
                id
                name
                position
                firma
                projecturl
                testimonial_hp
                featuredimage {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }  
            }
        }
      }
    `}
    render={(data) => <Testimonials data={data} />}
  />
)

export default Main;
