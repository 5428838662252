import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { graphql, StaticQuery } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"

const Customers = ({ data }) => {
  return (
    <div className="partner-area pt-100 pb-70 bg-white">
      <Container>
        <div className="section-title"><h2>Einige unserer Kunden</h2><p>Unsere <Link
          to={"/kundenprojekte/"}>Kundenprojekte</Link> zeigen Ihnen was wir konkret realisiert haben.</p></div>
        <Row className="align-items-center">
          {data.allFile.nodes.map((file) => (
            <Col md={4} lg={2} xs={6}>
                <div className="single-partner-item">
                  <GatsbyImage alt={file.relativePath}
                               image={getImage(file)}
                  />
                </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}


const Main = () => (
  <StaticQuery
    query={graphql`
      query CustomerImagesQuery {
        allFile(filter: {relativePath: {regex: "/customers/.*/"}}) {
            nodes {
                relativePath
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
            }
        }
      }
    `}
    render={(data) => <Customers data={data} />}
  />
)

export default Main;
