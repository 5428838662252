import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import aboutImage from "../../assets/images/about/about-img1.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUserFriends,
  faPeopleCarry,
  faUnlink,
  faSmileWink,
} from "@fortawesome/free-solid-svg-icons"
import { StaticImage } from "gatsby-plugin-image"
import { LogisticYears } from "./LogisticYears"


const AboutUsContent = () => {
  const startYear = 2003;
  const logisticYears = new Date().getFullYear() - startYear;

  return (
    <section className="about-area pb-70 pt-2">
      <div className="container-fluid">
        <Row className="align-items-center">
          <Col lg={6} md={12} className="align-self-start">
            <div className="about-image">
              <img src={aboutImage} alt="banner" />
            </div>
          </Col>

          <Col lg={6} md={12}>
            <div className="about-content">
              <div className="content">
                <h2>Software in der Logistik seit 2003</h2>
                <p>Wir sind die  <Link to={"/logistikexperten/"}>Experten</Link> für
                  Intralogistik <Link to={"/logistiksoftware/"}>Software</Link>. Mit klarem Fokus auf
                  Prozesse in der Logistik sind wir seit <LogisticYears /> Jahren für Kunden aus dem Mittelstand
                  aktiv. Wir konzentrieren uns dabei hauptsächlich auf die Branchen Handel und
                  Kontraktlogistik.</p>

                <Row className={"mb-4"}>
                  <Col lg={6} md={6}>
                    <div className={"d-flex flex-row border m-2"}>
                      <div className={"m-2"}>
                        <FontAwesomeIcon icon={faPeopleCarry} alt={"Logistik seit 2003"} size="3x" />
                      </div>
                      <div className={"m-1"}>
                        <h3 className={"mb-0"}>{logisticYears} Jahre</h3>
                        <p>Logistik</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className={"d-flex flex-row border m-2"}>
                      <div className={"m-2"}>
                        <FontAwesomeIcon icon={faUserFriends} alt={"Mitarbeiter"} size="3x" />
                      </div>
                      <div className={"m-1"}>
                        <h3 className={"mb-0"}>15+</h3>
                        <p>Mitarbeiter</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className={"d-flex flex-row border m-2"}>
                      <div className={"m-2"}>
                        <FontAwesomeIcon icon={faUnlink} alt={"Unabhängig"} size="3x" />
                      </div>
                      <div className={"m-1"}>
                        <h3 className={"mb-0"}>100%</h3>
                        <p>Unabhängig</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className={"d-flex flex-row border m-2"}>
                      <div className={"m-2"}>
                        <FontAwesomeIcon icon={faSmileWink} alt={"Kundenorientiert"} size="3x" />
                      </div>
                      <div className={"m-1"}>
                        <h3 className={"mb-0"}>100%</h3>
                        <p>Kundenorientiert</p>
                      </div>
                    </div>
                  </Col>
                </Row>


                <div className={"d-flex flex-md-row flex-column"}>
                  <Link to="/firmenhistorie/" className="btn btn-secondary mx-2 my-2 btn-lg">
                    Zur Firmenhistorie
                  </Link>

                  <Link to="/kundenprojekte/" className="btn btn-primary mx-2 my-2 btn-lg">
                    Zu den Projekten
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="circle-shape1">
        <StaticImage
          src="../../assets/images/shape/circle-shape1.png"
          alt="Ueber uns Shape"
          placeholder="blurred"
          className={"pb-70"}
        />
      </div>

      <Container>
        <div className="about-inner-area">
          <Row>
            <Col lg={4} sm={6}>
              <div className="about-text">
                <h3>Unsere Geschichte</h3>
                <p>Wir lieben Software und unsere Historie ist ein Beleg dafür. Wir denken
                  nur Logistik und machen nichts anderes.</p>

                <ul className="features-list text-left ml-3 ml-md-0">
                  <li><i className="flaticon-tick" /> Logistik Software seit {startYear}</li>
                  <li><i className="flaticon-tick" /> Partner der HS Osnabrück</li>
                  <li><i className="flaticon-tick" /> Viele erfolgreiche Projekte</li>
                  <li><i className="flaticon-tick" /> Out-of-the-Box Denker</li>
                </ul>
              </div>
            </Col>

            <Col lg={4} sm={6}>
              <div className="about-text">
                <h3>Unsere Mission</h3>
                <p>Fortschrittliche Software im Bereich der Logistik zu entwickeln unter Berücksichtigung
                  der Bedürfnisse der Workforce im Lager.</p>

                <ul className="features-list text-left ml-3 ml-md-0">
                  <li><i className="flaticon-tick" /> Innovation im Lager</li>
                  <li><i className="flaticon-tick" /> Beste Versandprozesse</li>
                  <li><i className="flaticon-tick" /> 100% Customizable Software</li>
                  <li><i className="flaticon-tick" /> Modernste Technologien</li>
                </ul>
              </div>
            </Col>

            <Col lg={{ offset: 0, span: 4 }} md={{ offset: 3, span: 6 }} sm={{ offset: 3, span: 6 }}>
              <div className="about-text">
                <h3>Wer wir sind</h3>
                <p>Eine Gruppe von enthusiastischen Menschen fasziniert von Technologie und
                  deren Einsatzmöglichkeiten in der Logistik.</p>

                <ul className="features-list text-left ml-3 ml-md-0">
                  <li><i className="flaticon-tick" /> Kundenorientiert</li>
                  <li><i className="flaticon-tick" /> Passioniert</li>
                  <li><i className="flaticon-tick" /> Resilient</li>
                  <li><i className="flaticon-tick" /> Lösungsorientiert</li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default AboutUsContent
